import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/header3';
import Footer from './../../Layout/footer4';
import PageTitle from './../../Layout/PageTitle';
import Sidebar from './../../Element/Sidebar';

//Images
import bnr1 from './../../../images/banner/bnr4.jpg';
// import grid1 from './../../../images/blog/grid/pic1.jpg';
// import grid2 from './../../../images/blog/grid/pic2.jpg';
// import grid3 from './../../../images/blog/grid/pic3.jpg';
// import grid4 from './../../../images/blog/grid/pic4.jpg';
/* const blogPost = [
	{images: grid1, }, {images: grid2, }, {images: grid3, },
	{images: grid1, }, {images: grid2, }, {images: grid3, },
	{images: grid4, }, {images: grid1, }, 
]; */
class BlogListLeftSidebar extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/*<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>*/}
					{/*	<PageTitle motherMenu='List Blog Left Sidebar' activeMenu='List Blog Left Sidebar' />*/}
                    {/*</div>*/}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Project Details' activeMenu='Project Details' />
                    </div>
                    <div className="content-block">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 sticky-top">
                                    <Sidebar />
                                </div>
                                <ListMainBlog    />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function ListMainBlog(){
	return(
		<>
			<div className="col-lg-8 fly-box-ho">

					<div className="blog-post blog-md clearfix" >
						<div className="dlab-post-info">
							<div className="dlab-post-title ">
								<h4 className="post-title"><Link to={"#"}>Why Dreamz' Academy is the right overseas educational facilitator for you?</Link></h4>
							</div>
							<p className="dlab-post-meta text-justify">
                            Overseas education is a complex and confusing process regardless of whether you are looking for a masters, bachelors, or diploma course. The process is highly competitive and requires high commitment and vigilance on your part while choosing an overseas education facilitator.</p>
                            <p className="text-justify"><ul> Please brief through to know why we are the best bet if you are looking for a credible overseas education facilitator.
                                <li>20 + years of expertise in Overseas Education facilitation</li>
                                <li> Officially representing over 500 + top-notch institutions worldwide</li>
                                <li>International university trained professional and experienced counselors</li>
                                <li>Thousands of successful student base across the globe</li>
                                <li>The highest visa success rate in South India</li>
                                <li>Hosting direct interviews from University representatives</li>
                                <li>IELTS Registration centre, Winner of numerous awards and commendations from IDP, foreign Universities and Governments</li>
                                <li>Authorized Pearson’s Test of English (PTE) and Professional partner – Pearson test of English-Academic, UKVI</li>
                                <li>Tie-ups with prominent banks for expedited education loans.</li>
                                
                            </ul>
                            </p> </div>


						</div>
					</div>
            <div className="col-lg-1"></div>

		</>
	)
}
export {ListMainBlog};
export default BlogListLeftSidebar;
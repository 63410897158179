import React, {Component,useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from './../../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import Counter from './../../Element/Counter';

//images
//import bnr1 from './../../../images/banner/bnr1.jpg';
import box1 from './../../../images/dreamz/pic2.jpg';
import box2 from './../../../images/dreamz/pic3.jpg';
import box3 from './../../../images/dreamz/pic4.jpg';
import box4 from './../../../images/dreamz/pic5.jpg';
import box5 from './../../../images/dreamz/pic6.jpg';
import box6 from './../../../images/dreamz/pic7.jpg';
import box7 from './../../../images/dreamz/pic8.jpg';
import box8 from './../../../images/dreamz/pic9.jpg';
import box9 from './../../../images/dreamz/pic10.jpg';
import box10 from './../../../images/dreamz/pic11.jpg';
import box11 from './../../../images/dreamz/pic12.jpg';
import box12 from './../../../images/dreamz/pic13.jpg';
import box13 from './../../../images/dreamz/pic14.jpg';
import box14 from './../../../images/dreamz/pic15.jpg';
import box15 from './../../../images/dreamz/pic16.jpg';
import box16 from './../../../images/dreamz/pic17.jpg';
import box17 from './../../../images/dreamz/pic18.jpg';
import box18 from './../../../images/dreamz/pic19.jpg';
import box19 from './../../../images/dreamz/pic20.jpg';
import box20 from './../../../images/dreamz/pic21.jpg';
import box21 from './../../../images/dreamz/pic22.jpg';
import box22 from './../../../images/dreamz/pic23.jpg';
import box23 from './../../../images/dreamz/pic24.jpg';
import box24 from './../../../images/dreamz/pic25.jpg';
import box25 from './../../../images/dreamz/pic26.jpg';

import box1b from './../../../images/results/ielts-results/pic2.png';
import box2b from './../../../images/results/ielts-results/pic3.png';
import box3b from './../../../images/results/ielts-results/pic4.png';
import box4b from './../../../images/results/ielts-results/pic5.png';
import box5b from './../../../images/results/ielts-results/pic6.png';
import box6b from './../../../images/results/ielts-results/pic7.png';
import box7b from './../../../images/results/ielts-results/pic8.png';
import box8b from './../../../images/results/ielts-results/pic9.png';
import box9b from './../../../images/results/ielts-results/pic10.png';
import box10b from './../../../images/results/ielts-results/pic11.png';
import box11b from './../../../images/results/ielts-results/pic12.png';
import box12b from './../../../images/results/ielts-results/pic13.png';
import box13b from './../../../images/results/ielts-results/pic14.png';
import box14b from './../../../images/results/ielts-results/pic15.png';
import box15b from './../../../images/results/ielts-results/pic16.png';
import box16b from './../../../images/results/ielts-results/pic17.png';
import box17b from './../../../images/results/ielts-results/pic18.png';
import box18b from './../../../images/results/ielts-results/pic19.png';
import box19b from './../../../images/results/ielts-results/pic20.png';
import box20b from './../../../images/results/ielts-results/pic21.png';
import box21b from './../../../images/results/ielts-results/pic22.png';
import box22b from './../../../images/results/ielts-results/pic23.png';
import box23b from './../../../images/results/ielts-results/pic24.png';
import box24b from './../../../images/results/ielts-results/pic25.png';
import box25b from './../../../images/results/ielts-results/pic26.png';
import box26b from './../../../images/results/ielts-results/pic27.png';
import box27b from './../../../images/results/ielts-results/pic28.png';
import box28b from './../../../images/results/ielts-results/pic29.png';
import box29b from './../../../images/results/ielts-results/pic30.png';
import box30b from './../../../images/results/ielts-results/pic31.png';
import box31b from './../../../images/results/ielts-results/pic32.png';
import box32b from './../../../images/results/ielts-results/pic33.png';
import box33b from './../../../images/results/ielts-results/pic34.png';
import box34b from './../../../images/results/ielts-results/pic35.png';
import box35b from './../../../images/results/ielts-results/pic36.png';
import box36b from './../../../images/results/ielts-results/pic37.png';
import box37b from './../../../images/results/ielts-results/pic38.png';
import box38b from './../../../images/results/ielts-results/pic39.png';
import box39b from './../../../images/results/ielts-results/pic40.png';
import box40b from './../../../images/results/ielts-results/pic41.png';
import box41b from './../../../images/results/ielts-results/pic42.png';
import box42b from './../../../images/results/ielts-results/pic43.png';
import box43b from './../../../images/results/ielts-results/pic44.png';
import box44b from './../../../images/results/ielts-results/pic45.png';
import box45b from './../../../images/results/ielts-results/pic45.png';
import box46b from './../../../images/results/ielts-results/pic46.png';
import box47b from './../../../images/results/ielts-results/pic47.png';
import box48b from './../../../images/results/ielts-results/pic48.png';

import box1a from './../../../images/results/pte-results/AMARPREET SINGH_._382195996_page-0001.jpg';
import box2a from './../../../images/results/pte-results/ANSHITA_._388460069_page-0001.jpg';
import box3a from './../../../images/results/pte-results/Anurag_Verma_376845808_page-0001.jpg';
import box4a from './../../../images/results/pte-results/APPARJOT KAUR_._454320908(1)_page-0001.jpg';
import box5a from './../../../images/results/pte-results/ARSHDEEP SINGH_._406025112_page-0001.jpg';
import box6a from './../../../images/results/pte-results/HARJEET SINGH_._408671450_page-0001.jpg';
import box7a from './../../../images/results/pte-results/JATIN_CHANANA_396554927_page-0001.jpg';
import box8a from './../../../images/results/pte-results/KIRANPREET KAUR_._388142790_page-0001.jpg';
import box9a from './../../../images/results/pte-results/MOHIT_DOAL_392118780_page-0001.jpg';
import box10a from './../../../images/results/pte-results/MUKESH_SYAL_455324270_page-0001.jpg';
import box11a from './../../../images/results/pte-results/NAVJOT KAUR_._452673105 (1)_page-0001.jpg';
import box12a from './../../../images/results/pte-results/PARTH_CHOPRA_384453636_page-0001.jpg';
import box13a from './../../../images/results/pte-results/PARVEEN RANI_._436990681_page-0001.jpg';
import box14a from './../../../images/results/pte-results/PAVNEET KAUR_._394726881_page-0001.jpg';
import box15a from './../../../images/results/pte-results/PRIYANKA_._435099705_page-0001.jpg';
import box16a from './../../../images/results/pte-results/Pulkit_Sharma_380727237 (1)_page-0001 (1).jpg';
import box17a from './../../../images/results/pte-results/Pulkit_Sharma_380727237 (1)_page-0001.jpg';
import box18a from './../../../images/results/pte-results/SHUBHAM_KHURANA_394768513 (1)_page-0001.jpg';
import box19a from './../../../images/results/pte-results/TARANPREET KAUR_._385160174_page-0001.jpg';
import box20a from './../../../images/results/pte-results/VISHAV PREET SINGH_._405958959_page-0001.jpg';


const imageBlog = [
	{ Large: box1 , tag: ['Visa Approved'] },
	{ Large: box2 , tag: ['Visa Approved'] }, 
	{ Large: box3 , tag: ['Visa Approved'] }, 
	{ Large: box4 , tag: ['Visa Approved'] },
	{ Large: box5 , tag: ['Visa Approved'] }, 
	{ Large: box6 , tag: ['Visa Approved'] }, 
	{ Large: box7 , tag: ['Visa Approved'] }, 
	{ Large: box8 , tag: ['Visa Approved'] }, 
	{ Large: box9 , tag: ['Visa Approved'] }, 
	{ Large: box11 , tag: ['Visa Approved'] }, 
	{ Large: box10 , tag: ['Visa Approved'] }, 
	{ Large: box12 , tag: ['Visa Approved'] }, 
	{ Large: box13 , tag: ['Visa Approved'] },
	{ Large: box14 , tag: ['Visa Approved'] },
	{ Large: box15 , tag: ['Visa Approved'] },
	{ Large: box16 , tag: ['Visa Approved'] },
	{ Large: box17 , tag: ['Visa Approved'] },
	{ Large: box18 , tag: ['Visa Approved'] },
	{ Large: box19 , tag: ['Visa Approved'] },
	{ Large: box20 , tag: ['Visa Approved'] },
	{ Large: box21 , tag: ['Visa Approved'] },
	{ Large: box22 , tag: ['Visa Approved'] },
	{ Large: box23 , tag: ['Visa Approved'] },
	{ Large: box24 , tag: ['Visa Approved'] },
	{ Large: box25 , tag: ['Visa Approved'] },
	{ Large: box1a , tag: ['PTE'] },
	{ Large: box2a , tag: ['PTE'] }, 
	{ Large: box3a , tag: ['PTE'] }, 
	{ Large: box4a , tag: ['PTE'] },
	{ Large: box5a , tag: ['PTE'] }, 
	{ Large: box6a , tag: ['PTE'] }, 
	{ Large: box7a , tag: ['PTE'] }, 
	{ Large: box8a , tag: ['PTE'] }, 
	{ Large: box9a , tag: ['PTE'] }, 
	{ Large: box11a , tag: ['PTE'] }, 
	{ Large: box10a , tag: ['PTE'] }, 
	{ Large: box12a , tag: ['PTE'] }, 
	{ Large: box13a , tag: ['PTE'] },
	{ Large: box14a , tag: ['PTE'] },
	{ Large: box15a , tag: ['PTE'] },
	{ Large: box16a , tag: ['PTE'] },
	{ Large: box17a , tag: ['PTE'] },
	{ Large: box18a , tag: ['PTE'] },
	{ Large: box19a , tag: ['PTE'] },
	{ Large: box20a , tag: ['PTE'] },
	{ Large: box1b , tag: ['IELTS'] },
	{ Large: box2b , tag: ['IELTS'] },
	{ Large: box3b , tag: ['IELTS'] },
	{ Large: box4b , tag: ['IELTS'] },
	{ Large: box5b , tag: ['IELTS'] },
	{ Large: box6b , tag: ['IELTS'] }, 
	{ Large: box7b , tag: ['IELTS'] }, 
	{ Large: box8b , tag: ['IELTS'] }, 
	{ Large: box9b , tag: ['IELTS'] }, 
	{ Large: box11b , tag: ['IELTS'] }, 
	{ Large: box10b , tag: ['IELTS'] }, 
	{ Large: box12b , tag: ['IELTS'] }, 
	{ Large: box13b , tag: ['IELTS'] },
	{ Large: box14b , tag: ['IELTS'] },
	{ Large: box15b , tag: ['IELTS'] },
	{ Large: box16b , tag: ['IELTS'] },
	{ Large: box17b , tag: ['IELTS'] },
	{ Large: box18b , tag: ['IELTS'] },
	{ Large: box19b , tag: ['IELTS'] },
	{ Large: box20b , tag: ['IELTS'] },
	{ Large: box21b , tag: ['IELTS'] },
	{ Large: box22b , tag: ['IELTS'] },
	{ Large: box23b , tag: ['IELTS'] },
	{ Large: box24b , tag: ['IELTS'] },
	{ Large: box25b , tag: ['IELTS'] },
	{ Large: box26b , tag: ['IELTS'] }, 
	{ Large: box27b , tag: ['IELTS'] }, 
	{ Large: box28b , tag: ['IELTS'] }, 
	{ Large: box29b , tag: ['IELTS'] }, 
	{ Large: box30b , tag: ['IELTS'] }, 
	{ Large: box31b , tag: ['IELTS'] }, 
	{ Large: box32b , tag: ['IELTS'] }, 
	{ Large: box33b , tag: ['IELTS'] },
	{ Large: box34b , tag: ['IELTS'] },
	{ Large: box35b , tag: ['IELTS'] },
	{ Large: box36b , tag: ['IELTS'] },
	{ Large: box37b , tag: ['IELTS'] },
	{ Large: box38b , tag: ['IELTS'] },
	{ Large: box39b , tag: ['IELTS'] },
	{ Large: box40b , tag: ['IELTS'] },
	{ Large: box41b , tag: ['IELTS'] },
	{ Large: box42b , tag: ['IELTS'] },
	{ Large: box43b , tag: ['IELTS'] },
	{ Large: box44b , tag: ['IELTS'] },
	{ Large: box45b , tag: ['IELTS'] },
	{ Large: box46b , tag: ['IELTS'] },
	{ Large: box47b , tag: ['IELTS'] },
	{ Large: box48b , tag: ['IELTS'] },
]

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry radius-sm" ><span>{name} {''}</span> </Link>
		</li> 
	);
};


class ProtfolioFullWidth extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="bg-black"  >
                        <PageTitle motherMenu='Latest Result' activeMenu='Latest Result' style={{ padding:"5px" }} />
                    </div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>
			<Footer/>
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	const [tag, setTag] = useState('All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
		<>
			<div className="section-full content-inner-1 portfolio text-uppercase">		
				<div className="site-filters clearfix center  m-b40">
					<ul className="filters" data-toggle="buttons">
						<TagLi name="All"  handlesettag={setTag} tagActive={ tag === 'All' ? true : false }	/>
						<TagLi name="IELTS"  handlesettag={setTag} tagActive={ tag === 'IELTS' ? true : false }	/>
						<TagLi name="PTE" handlesettag={setTag} tagActive={ tag === 'PTE' ? true : false }	 />
						<TagLi name="Visa Approved"  handlesettag={setTag} tagActive={ tag === 'Visa Approved' ? true : false }	/>
						
					</ul>
				</div>
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="clearfix">
							<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
								<Masonry
									className={'my-gallery-class'} // default ''
									options={masonryOptions} // default {}
									disableImagesLoaded={false} // default false
									updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
									imagesLoadedOptions={imagesLoadedOptions} // default {}
								>
									{filteredImages.map((item, index)=>(
										<li className="web design card-container col-lg-3 col-md-6 col-xs-12 col-sm-6 p-a0" key={index}>
											<div className="dlab-box dlab-gallery-box">
												<div className="dlab-media dlab-img-overlay1 dlab-img-effect"> 
													<img src={item.Large} alt="" />
													<div className="overlay-bx">
														<div className="overlay-icon align-b text-white text-left">
															<div className="text-white text-left port-box">
																
																<Iconimage />
															</div>
														</div>
													</div>
												</div>
											</div>
										</li>
									))}	
								</Masonry>	
							</ul>
						</div>
					</SRLWrapper>
				</SimpleReactLightbox>	
				<Counter/>
			</div>
		</>
	);
}
export {box1, box2, box3, box4, box5, box6, box7, box8, box9, box10, box11, box12, box13, box14, box15, box16, box17, box18, box19, box20, box21, box22, box23, box24, box25, imageBlog};
export default ProtfolioFullWidth;
